import "assets/styles/fonts.scss";
import "assets/styles/normalize.scss";

import "pages/index/index.scss";

import smoothscrollPolyfill from "smoothscroll-polyfill";

smoothscrollPolyfill.polyfill();

window.addEventListener("DOMContentLoaded", () =>
{
    document.querySelector("#hamburger").addEventListener("click", () =>
    {
        window.scrollTo(0, 0);

        document.querySelector("body").style.overflow = "hidden";
        document.querySelector(".header").style.position = "fixed";

        document.querySelector(".header").classList.add("active");
    });

    document.querySelector("#cross").addEventListener("click", () =>
    {
        document.querySelector("body").style.overflow = "visible";
        document.querySelector(".header").style.position = "static";

        document.querySelector(".header").classList.remove("active");
    });

    for(let link of document.querySelectorAll(".navigation__item"))
    {
        link.addEventListener("click", () =>
        {
            document.querySelector("body").style.overflow = "visible";
            document.querySelector(".header").style.position = "static";

            document.querySelector(".header").classList.remove("active");
        });
    }

    for(let marker of document.querySelectorAll(".map__marker"))
    {
        marker.addEventListener("mouseenter", () =>
        {
            for(let marker of document.querySelectorAll(".map__marker"))
            {
                marker.dataset.active = "false";
            }

            marker.dataset.active = "true";
        });

        marker.addEventListener("touchstart", () =>
        {
            for(let marker of document.querySelectorAll(".map__marker"))
            {
                marker.dataset.active = "false";
            }

            marker.dataset.active = "true";
        });
    }
});

window.addEventListener("resize", () =>
{
    if(window.innerWidth >= 768)
    {
        document.querySelector("body").style.overflow = "visible";
        document.querySelector(".header").style.position = "static";

        document.querySelector(".header").classList.remove("active");
    }
});